@media only screen and (min-width: 1280px) {
    .rentinazobg {
        background-image: url('/assets/landings/rentinazo/cabecera_rentinazo.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.svg-inline--fa {
    height: 1em;
    vertical-align: -0.125em;
    display: inline;
}

.degLightBlue {
    background: linear-gradient(90deg, #0388ab -21.9%, #5fc0ce 109.92%);
}

.degPrimary {
    background: linear-gradient(270deg, #f06400 -4.92%, #ed8b00 88.11%);
}

.degPrimaryVariant {
    background: linear-gradient(0deg, #f06400 -21.23%, #ed8b00 122.12%);
}

.prettymarkdown ul {
    list-style-position: inside;
    list-style-type: disc;
}

.prettymarkdown ol {
    list-style-position: outside;
    list-style-type: none;
}

.prettymarkdown ol > li {
    margin-top: 1rem;
}

.prettymarkdown p {
    margin-top: 1rem;
}

.prettymarkdown p + strong {
    display: block;
}

.prettymarkdown em {
}

.prettymarkdown em::after {
    content: '\a';
    white-space: pre;
}

.betterstyles > * + * {
    margin-top: 1rem;
}

.betterstyles h2 {
    @apply pb-2 pt-4 text-left text-2xl font-semibold text-lpsecondary;
}

.betterstyles.faq > div {
    @apply max-w-3xl rounded-lg bg-white p-6 shadow-lg;
}

.betterstyles.faq b,
.betterstyles.faq strong {
    @apply font-semibold text-black;
}

.betterstyles a {
    @apply text-lpprimary;
}

.betterstyles .MsoListParagraphCxSpFirst,
.betterstyles .MsoListParagraphCxSpMiddle,
.betterstyles .MsoListParagraphCxSpLast {
    margin-left: 0pt !important;
    text-indent: 0pt !important;
    padding-left: 18px;
}

.betterstyles ul .MsoListParagraphCxSpFirst span[style*='Symbol'],
.betterstyles ul .MsoListParagraphCxSpMiddle span[style*='Symbol'],
.betterstyles ul .MsoListParagraphCxSpLast span[style*='Symbol'] {
    display: none;
}

.betterstyles.faq div[itemprop='acceptedAnswer'] > p {
    @apply font-normal !text-black;
}

.betterstyles h3 {
    font-weight: 600;
}

.betterstyles.faq h3 + p,
.betterstyles.faq strong + p,
.betterstyles.faq p {
    @apply font-normal text-black;
}

.betterstyles.faq > p:first-of-type,
.betterstyles.faq > div > p:first-child,
.betterstyles.faq h3 {
    margin-bottom: 1rem;
    @apply font-semibold text-lpprimary;
}

.betterstyles.faq div > h3 + p,
.betterstyles.faq div > ul + p {
    @apply !font-normal !text-black;
}

.betterstyles li {
    @apply list-inside list-disc;
}

.betterstyles li::marker {
    @apply text-lpprimary;
}

.betterstyles.content h3 + p + div {
    @apply grid grid-cols-1 gap-4 pt-2 lg:grid-cols-4;
}

.betterstyles.content .cards {
    @apply grid grid-cols-1 gap-16 pt-8 md:grid-cols-2;
}

.betterstyles.content .cards > div {
    @apply overflow-hidden rounded-lg bg-white shadow-lg;
}

.betterstyles.content .cards > div > img {
    @apply w-full;
}

.betterstyles.content .cards > div p,
.betterstyles.content .cards > div h2,
.betterstyles.content .cards > div ul,
.betterstyles.content .cards > div ol {
    @apply list-inside px-6 py-3 text-base;
}

.betterstyles.content h3 {
    @apply pb-1 pt-4 text-left text-lg font-semibold text-lpsecondary;
}

.betterstyles.content .cards > div:first-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 2.jpg');
}

.betterstyles.content .cards > div:last-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 1.jpg');
}

.betterstyles.content.diesel .cards > div:first-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 3.jpg');
}

.betterstyles.content.diesel .cards > div:last-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 4.jpg');
}

.betterstyles.content.gasolina .cards > div:first-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 5.jpg');
}

.betterstyles.content.gasolina .cards > div:last-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 6.jpg');
}

.betterstyles.content.electrico .cards > div:first-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 7.jpg');
}

.betterstyles.content.electrico .cards > div:last-of-type > img:not(.cms) {
    content: url('/assets/global/random/fotos alberto 8.jpg');
}

.betterstyles.content.hibrido .cards > div:first-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 10.jpg');
}

.betterstyles.content.hibrido .cards > div:last-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 9.jpg');
}

.betterstyles.content.automatico .cards > div:first-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 11.jpg');
}

.betterstyles.content.automatico .cards > div:last-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 12.jpg');
}

.betterstyles.content.manual .cards > div:first-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 13.jpg');
}

.betterstyles.content.manual .cards > div:last-of-type > img:not(.cms) {
    content: url('/assets/global/random/foto alberto 14.jpg');
}

.betterstyles.content {
    margin-top: 4rem;
}

.betterstyles.faq {
    padding-top: 4rem;
    display: block;
    clear: both;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swipercenter .swiper-slide {
    @apply flex items-center justify-center;
}

.swiper-button-next {
    right: 0px;
}

.swiper-button-prev {
    left: 0px;
}

.OfertasRentingCochesSwipper .swiper-button-next {
    right: 20px;
}

.OfertasRentingCochesSwipper .swiper-button-prev {
    left: 20px;
}

.OfertasRentingCochesSwipper .swiper-pagination {
    left: 6rem !important;
    bottom: 6rem;
}

.testimonials .swiper-slide {
    margin-right: 0px;
    max-width: 580px;
}

.list-outside li div {
    position: relative;
    top: -0.25rem;
    padding-left: 0.25rem;
}

.list-outside li {
    margin-bottom: 0.25rem;
}

/* MOTOS */

.button.small {
    @apply px-8 py-2 text-xs;
}

.button {
    @apply inline-flex items-center rounded-full border border-transparent bg-lpprimary px-12 py-3 font-bold shadow-sm hover:opacity-60 focus:outline-none focus:ring-0;
    text-transform: uppercase;
    color: white;
}

.button.inverted {
    @apply bg-white text-lpprimary;
}

.button.green {
    @apply bg-lpfreshgreen;
}

.h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
}

.marcaslpgbtn {
    height: 60px;
    line-height: 60px;
    font-weight: bold;
    background: #000000;
    color: #ffffff !important;
    border: 0;
    cursor: pointer;
    font-size: 18px;
    display: inline-block;
    text-align: center;
    padding: 0 16px;
    text-decoration: none;
    background: #000000;
    -webkit-box-shadow:
        3px 3px 0 0 rgba(0, 0, 0, 0.2),
        inset 0 27px 0 0 rgba(255, 255, 255, 0.2);
    box-shadow:
        3px 3px 0 0 rgba(0, 0, 0, 0.2),
        inset 0 27px 0 0 rgba(255, 255, 255, 0.2);
    padding: 0 24px;
}

.marcaslpgbtn:hover {
    -webkit-box-shadow:
        3px 3px 0 0 rgba(0, 0, 0, 0.2),
        inset 0 27px 0 0 rgba(255, 255, 255, 0.3);
    box-shadow:
        3px 3px 0 0 rgba(0, 0, 0, 0.2),
        inset 0 27px 0 0 rgba(255, 255, 255, 0.3);
}

.marcaslpgbtn:active {
    position: relative;
    top: 2px;
    left: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.marcaslpgbtnlarge {
    width: 100%;
}

.buttonarea {
    @apply inline-flex items-center rounded-full border border-transparent bg-lpprimary px-12 py-3 font-bold shadow-sm hover:bg-lpprimary hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: white;
}

.buttonarea .inverted {
    background-color: #f06400;
    @apply text-white;
}

.buttonarea .inverted:hover {
    background-color: #c2410c;
}

/* Para onetrust */
.ot-floating-button__open svg {
    display: inline;
}

table.legal {
    border: 1px !important;
    border-radius: 5px !important;
    white-space: normal !important;
    overflow: hidden;
}

table.legal td,
th {
    border: 1px solid #d7d7d7 !important;
    padding: 0.5rem;
}

table.legal td a,
th a {
    text-decoration: underline;
}

.ot-cookies-td {
    word-break: break-all;
}

#onetrust-banner-sdk #onetrust-reject-all-handler {
    color: #f06400 !important;
    border-color: #f06400 !important;
    background-color: #ffffff !important;
}
